<template>
<div>
  <!-- <Top></Top>
  <Fix></Fix> -->
  <div class="container">
    <keep-alive :include="['intellectualProperty']">
      <router-view :key="key"></router-view>
    </keep-alive>
  </div>
  <!-- <Foot></Foot> -->
</div>
</template>

<script>
// import Foot from "../ui/foot";
// import Fix from "../ui/fix";
// import Top from "../ui/top";
export default {
  name: "Layout",
  computed:{
    key(){
      return this.$route.path
    }
  },
  components: {}
}
</script>

<style scoped>
.container{
  width:1200px;
  margin:0 auto;
  min-height:calc(100vh - 304px);
  padding-bottom: 30px;
}
</style>
